<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title class="headline">TY Ürün Tanımla</v-card-title>
          <v-card-text>
            <v-form>
              <v-text-field v-model="newData.barcode" label="Barcode Giriniz..."></v-text-field>
              <v-text-field v-model="newData.comission" label="Komisyon Giriniz.." type="number"></v-text-field>
              <v-text-field v-model="newData.cost" label="Maliyet Giriniz..." type="number"></v-text-field>
              <v-btn style="width: 100%;" @click="addData" color="primary">Ekle</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card>
          <v-card-title class="headline">Ürün Listesi</v-card-title>
          <v-card-text>
            Toplam Ürün Sayısı: {{ data.length }}
          </v-card-text>
          <v-simple-table style="margin-top: 8px;">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Barcode</th>
                  <th>Comission</th>
                  <th>Cost</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in data" :key="index">
                  <td>{{ item.barcode }}</td>
                  <td>{{ item.comission }}</td>
                  <td>{{ item.cost }}</td>
                  <td>
                    <v-btn style="margin: 3px;" @click="editItem(index)" color="primary">Düzenle</v-btn>
                    <v-btn style="margin: 3px;" @click="deleteItem(index)" color="error">Sil</v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="editDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Veriyi Düzenle</v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field v-model="editData.barcode" label="Barcode"></v-text-field>
            <v-text-field v-model="editData.comission" label="Comission" type="number"></v-text-field>
            <v-text-field v-model="editData.cost" label="Cost" type="number"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="saveEdit" color="primary">Kaydet</v-btn>
          <v-btn @click="cancelEdit" color="error">İptal</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { API_FIREBASE_TY_PRODUCTS } from '../../../secret.json';
export default {
  data() {
    return {
      data: [],
      newData: {
        barcode: '',
        comission: 0,
        cost: 0
      },
      editDialog: false,
      editData: {
        barcode: '',
        comission: 0,
        cost: 0
      },
      editIndex: -1
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      fetch(API_FIREBASE_TY_PRODUCTS)
        .then(response => response.json())
        .then(data => {
          if (data) {
            this.data = data;
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    },
    addData() {
      const newItem = { ...this.newData };
      this.data.push(newItem);

      fetch(API_FIREBASE_TY_PRODUCTS, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.data)
      })
      .then(response => {
        if (response.ok) {
          this.fetchData(); // Refresh data
          this.newData = {
            barcode: '',
            comission: 0,
            cost: 0
          };
        } else {
          console.error('Error adding data:', response);
        }
      })
      .catch(error => {
        console.error('Error adding data:', error);
      });
    },
    editItem(index) {
      this.editIndex = index;
      this.editData = { ...this.data[index] };
      this.editDialog = true;
    },
    saveEdit() {
      if (this.editIndex > -1) {
        this.data[this.editIndex] = { ...this.editData };

        fetch(API_FIREBASE_TY_PRODUCTS, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.data)
        })
        .then(response => {
          if (response.ok) {
            this.fetchData(); // Refresh data
            this.editDialog = false;
            this.editIndex = -1;
          } else {
            console.error('Error editing data:', response);
          }
        })
        .catch(error => {
          console.error('Error editing data:', error);
        });
      }
    },
    cancelEdit() {
      this.editDialog = false;
      this.editIndex = -1;
    },
    deleteItem(index) {
      this.data.splice(index, 1);

      fetch(API_FIREBASE_TY_PRODUCTS, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.data)
      })
      .then(response => {
        if (response.ok) {
          this.fetchData(); // Refresh data
        } else {
          console.error('Error deleting data:', response);
        }
      })
      .catch(error => {
        console.error('Error deleting data:', error);
      });
    }
  }
};
</script>

<style scoped>
</style>
